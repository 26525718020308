<template>
  <div class="app">
    <apexcharts
      width="550"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Chart",
  props: {
    series: {
      default: () => [],
      type: Array,
    },
    category: {
      default: () => [],
      type: Array,
    },
    title: {
      default: "",
      type: String,
    },
  },
  components: {
    apexcharts: VueApexCharts,
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: "100%",
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width:2,
          colors: ["#fff"],
        },
        title: {
          text: this.title,
        },
        dataLabels: {
          style: {
            fontSize: "6px",
          }, // this do not work!
        },
        xaxis: {
          categories: this.category,
          labels: {
            style: {
              fontSize: "6px",
            },
            formatter: function (val) {
              return val + "%";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined
          },
          labels:{
            style: {
              fontSize: "8px",
            },
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%";
            },
          },
        },
        fill: {
          opacity: 1,
        },
      
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
          fontSize: "8px",
        },
      },
    };
  },
  methods: {},
  computed: {
    currentCategory() {
      return this.category;
    },
  },

  watch: {
    currentCategory(value) {
      if (!value) {
        return;
      }

      this.chartOptions = {
        xaxis: {
          categories: this.category,
        },
        title: {
          text: this.title,
        },
      };
    },
  },
};
</script>
<style scoped>
button {
  background: #26e6a4;
  border: 0;
  font-size: 16px;
  color: "#fff";
  padding: 10px;
  margin-left: 28px;
}
</style>
