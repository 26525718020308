<template>
  <div class="app">
    <apexcharts
      width="550"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Chart",
  props: {
    series: {
      default: () => [],
      type: Array,
    },
    category: {
      default: () => [],
      type: Array,
    },
    title: {
      default: "",
      type: String,
    },
  },
  components: {
    apexcharts: VueApexCharts,
  },
  data: function() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 70,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: this.title,
        },
        xaxis: {
          type: "category",
          categories: this.series,
          labels: {
            formatter: function(val) {
              return val + "";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
    };
  },
  methods: {
    getData() {
      this.chartOptions = {
        xaxis: {
          categories: this.category,
        },
      };
    },
  },
  computed: {
    currentCategory() {
      return this.category;
    },
  },

  watch: {
    currentCategory(value) {
      // console.log("test", value);

      if (!value) {
        return;
      }

      this.chartOptions = {
        xaxis: {
          categories: this.category,
        },
      };
    },
  },
};
</script>
