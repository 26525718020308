<template>
  <div data-app>
    <!--begin::Dashboard-->
    <div class="row mb-3">
      <div class="col-xxl-2 mb-2">
        <div class="card" style="height: 7em;">
          <div
            class="card-body"
            style="padding: 0.8em; display: flex; align-items: center;"
          >
            <div class="d-flex align-items-center">
              <div>
                <i class="fas fa-user-circle fa-3x text-secondary"></i>
              </div>
              <div class="text-center ml-5">
                <div>
                  Project Count
                </div>
                <div>
                  <p
                    class="mb-0"
                    style="fontSize: 2rem; fontWeight: bold;"
                    @click="showJumlahProyekList"
                  >
                    <a class="text-decoration-none">
                      {{ this.currentCounterProject }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-2 mb-2">
        <div class="card" style="height: 7em;">
          <div
            class="card-body"
            style="padding: 0.8em; display: flex; align-items: center;"
          >
            <div class="d-flex align-items-center">
              <div>
                <i class="fas fa-book fa-3x text-secondary"></i>
              </div>
              <div class="text-center ml-5">
                <div>
                  DMP Released
                </div>
                <div>
                  <p
                    class="mb-0"
                    style="fontSize: 2rem; fontWeight: bold;"
                    @click="showDMPReleasedList"
                  >
                    <a class="text-decoration-none">
                      {{ this.currentCounterDMP }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-2 mb-2">
        <div class="card" style="height: 7em;">
          <div
            class="card-body"
            style="padding: 0.8em; display: flex; align-items: center;"
          >
            <div class="d-flex align-items-center">
              <div>
                <i class="fas fa-sticky-note fa-3x"></i>
              </div>
              <div class="text-center ml-5">
                <div>
                  Open Item MoM
                </div>
                <div>
                  <p
                    class="mb-0"
                    style="fontSize: 2rem; fontWeight: bold;"
                    @click="showOpenItemMomList"
                  >
                    <a class="text-decoration-none">
                      {{ this.currentCounterMOM }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-2 mb-2">
        <div class="card" style="height: 7em;">
          <div
            class="card-body"
            style="padding: 0.8em; display: flex; align-items: center;"
          >
            <div class="d-flex align-items-center">
              <div>
                <i class="fas fa-box fa-3x text-secondary"></i>
              </div>
              <div class="text-center ml-5">
                <div>
                  Return status
                </div>
                <div>
                  <p
                    class="mb-0"
                    style="fontSize: 2rem; fontWeight: bold;"
                    @click="showReturnStatusList"
                  >
                    <a class="text-decoration-none">
                      {{ counterReturnStatus }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-2 mb-2">
        <div class="card" style="height: 7em;">
          <div
            class="card-body"
            style="padding: 0.8em; display: flex; align-items: center;"
          >
            <div class="d-flex align-items-center">
              <div>
                <i class="fas fa-cogs fa-3x text-secondary"></i>
              </div>
              <div class="text-center ml-5">
                <div>
                  Change Management
                </div>
                <div>
                  <p
                    class="mb-0"
                    style="fontSize: 2rem; fontWeight: bold;"
                    @click="showCRList"
                  >
                    <a class="text-decoration-none">
                      {{ this.currentCounterCR }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-2 mb-2">
        <div class="card" style="height: 7em;">
          <div
            class="card-body"
            style="padding: 0.8em; display: flex; align-items: center;"
          >
            <div class="d-flex align-items-center">
              <div>
                <i class="fas fa-arrow-circle-up fa-3x text-secondary"></i>
              </div>
              <div class="text-center ml-5">
                <div>
                  Upload server
                </div>
                <div>
                  <p
                    class="mb-0"
                    style="fontSize: 2rem; fontWeight: bold;"
                    @click="showUploadServerList"
                  >
                    <a class="text-decoration-none">
                      {{ this.counterUploadServer }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-2 mb-2">
        <div class="card" style="height: 7em;">
          <div
            class="card-body"
            style="padding: 0.8em; display: flex; align-items: center;"
          >
            <div class="d-flex align-items-center">
              <div>
                <i class="fas fa-calendar-alt fa-3x text-secondary"></i>
              </div>
              <div class="text-center ml-5">
                <div>
                  Plan date
                </div>

                <div>
                  <p
                    class="mb-0"
                    style="fontSize: 2rem; fontWeight: bold;"
                    @click="showPlanDateList"
                  >
                    <a class="text-decoration-none">
                      {{ this.currentCounterPlan }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-2 mb-2">
        <div class="card" style="height: 7em;">
          <div
            class="card-body"
            style="padding: 0.8em; display: flex; align-items: center;"
          >
            <div class="d-flex align-items-center">
              <div>
                <i class="fas fa-book fa-3x text-secondary"></i>
              </div>
              <div class="text-center ml-5">
                <div>
                  Urgent
                </div>
                <div>
                  <p
                    class="mb-0"
                    style="fontSize: 2rem; fontWeight: bold;"
                    @click="showUrgentList"
                  >
                    <a class="text-decoration-none">
                      {{ this.counterUrgent }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-2 mb-2">
        <div class="card" style="height: 7em;">
          <div
            class="card-body"
            style="padding: 0.8em; display: flex; align-items: center;"
          >
            <div class="d-flex align-items-center">
              <div>
                <i class="fas fa-sticky-note fa-3x text-secondary"></i>
              </div>
              <div class="text-center ml-5">
                <div>
                  Delay
                </div>
                <div>
                  <p
                    class="mb-0"
                    style="fontSize: 2rem; fontWeight: bold;"
                    @click="showDelayList"
                  >
                    <a class="text-decoration-none">
                      {{ this.counterDelay }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-2 mb-2">
        <div class="card" style="height: 7em;">
          <div
            class="card-body"
            style="padding: 0.8em; display: flex; align-items: center;"
          >
            <div class="d-flex align-items-center">
              <div>
                <i class="fas fa-file fa-3x text-secondary"></i>
              </div>
              <div class="text-center ml-5">
                <div>
                  Design Updated
                </div>
                <div>
                  <p
                    class="mb-0"
                    style="fontSize: 2rem; fontWeight: bold;"
                    @click="showDesignUpdatedList"
                  >
                    <a class="text-decoration-none">
                      {{ this.currentCounterDesignUpdated }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- search column -->

    <div class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-row class="my-1 d-flex align-items-center">
              <b-col sm="3">
                <label>Project Code :</label>
              </b-col>
              <b-col sm="9">
                <b-form-select
                  v-model="filter.code_project"
                  :options="formattedProjectOptions"
                  size="sm"
                  class="mt-3"
                ></b-form-select>
              </b-col>
            </b-row>
            <div class="row">
              <div class="col-12">
                <div class="text-right">
                  <button class="btn btn-primary" @click="onTampilkanData">
                    Show
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end search column -->

    <!-- horizontal chart column -->

    <div class="row mb-3">
      <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-body">
            <ChartBar
              :category="chartProgressOverview.category"
              :series="chartProgressOverview.series"
              title="Progress Overview"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-body">
            <ChartBar
              :category="chartProgressOverviewUser.category"
              :series="chartProgressOverviewUser.series"
              :title="chartProgressOverviewUser.title"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- end horizontal chart column -->

    <!-- table target overview -->

    <div class="row mb-3">
      <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h5>Target Overview</h5>
            </div>
            <div class="table-responsive">
              <complete-table :loading="false" :data="tableOverview">
                <template #header>
                  <tr>
                    <th>Scope</th>
                    <th>No target</th>
                    <th>Remain</th>
                    <th>Next mo</th>
                    <th>Delay</th>
                    <th>Urgent</th>
                  </tr>
                </template>
                <template #defaultBody="{ item, i }">
                  <tr v-bind:key="i">
                    <td>{{ item.scope }}</td>
                    <td>
                      <a
                        class="font-weight-bold d-block"
                        href="#"
                        @click.prevent="onViewDetail(item, 'no_target')"
                      >
                        {{ item.no_target }}
                      </a>
                    </td>
                    <td>
                      <a
                        class="font-weight-bold d-block"
                        href="#"
                        @click.prevent="onViewDetail(item, 'remain')"
                      >
                        {{ item.remain }}
                      </a>
                    </td>
                    <td>
                      <a
                        class="font-weight-bold d-block"
                        href="#"
                        @click.prevent="onViewDetail(item, 'next_mo')"
                      >
                        {{ item.next_mo }}
                      </a>
                    </td>
                    <td>
                      <a
                        class="font-weight-bold d-block text-danger"
                        href="#"
                        @click.prevent="onViewDetail(item, 'delay')"
                      >
                        {{ item.delay }}
                      </a>
                    </td>
                    <td>
                      <a
                        class="font-weight-bold d-block text-danger"
                        href="#"
                        @click.prevent="onViewDetail(item, 'urgent')"
                      >
                        {{ item.urgent }}
                      </a>
                    </td>
                  </tr>
                </template>
              </complete-table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h5>
                Urgent (
                {{
                  this.currentLogin != null ? this.currentLogin.pic.code : null
                }}
                )
              </h5>
            </div>
            <div class="table-responsive">
              <complete-table :loading="false" :data="tableUrgent">
                <template #header>
                  <tr>
                    <th>Scope</th>
                    <th>Urgent</th>
                  </tr>
                </template>
                <template #defaultBody="{ item, i }">
                  <tr v-bind:key="i">
                    <td>{{ item.scope }}</td>
                    <td>
                      {{ item.urgent }}
                    </td>
                  </tr>
                </template>
              </complete-table>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        ref="view-detail-target"
        hide-footer
        title="Detail Target Overview"
        size="xl"
      >
        <div class="d-flex justify-content-end mt-3">
          <vue-excel-xlsx
            :data="tableDetailOverview"
            :columns="columns"
            :file-name="'DetailTargetOverview'"
            :sheetname="'sheet1'"
          >
            <button
              class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
              :disabled="tableDetailOverview.length === 0"
            >
              <i class="fa fa-file-excel"></i>
              Export
            </button>
          </vue-excel-xlsx>
        </div>
        <div class="table-responsive mt-3">
          <complete-table :loading="false" :data="tableDetailOverview">
            <template #header>
              <tr class="text-left">
                <th style="min-width: 50px">
                  No.
                </th>
                <th style="min-width: 100px">Numbering</th>
                <th style="min-width: 100px">Title</th>
                <th style="min-width: 100px">Status</th>
                <th style="min-width: 100px">Station</th>
                <th style="min-width: 100px">PIC Designer</th>
                <th style="min-width: 100px">Planning Date</th>
              </tr>
            </template>
            <template #defaultBody="{ item, i, number }">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <span
                    class=" ml-3 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ number }}
                  </span>
                </td>
                <td>
                  <span
                    class=" text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.numbering }}
                  </span>
                </td>
                <td>
                  <span
                    class=" text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>
                <td>
                  <span
                    class="ml-3 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.last_status_code ? item.last_status_code : "-" }}
                  </span>
                </td>
                <td>
                  <span
                    class=" text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.station.name ? item.station.name : "-" }}
                  </span>
                </td>
                <td>
                  <span
                    class=" text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.designer_pic ? item.designer_pic.name : "-" }}
                  </span>
                </td>
                <td>
                  <span
                    class=" text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.planning_date }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </div>
      </b-modal>
    </div>

    <!-- end table target overview -->

    <!-- vertical chart bar -->

    <div class="row mb-3">
      <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-body">
            <ChartVertical
              :category="chartProgressCategory.category"
              :series="chartProgressCategory.series"
              title="Category Overview"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-body">
            <ChartVertical
              :category="chartProgressType.category"
              :series="chartProgressType.series"
              title="Type Overview"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- end vertical chart bar -->

    <!-- last chart -->

    <div class="row mb-3">
      <div class="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12">
        <div class="card" style="max-height: 32em;">
          <div class="card-body">
            <ChartLine
              :category="this.chartPlanDate.category"
              :series="this.chartPlanDate.series"
              title="Target Plan"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
        <div class="card" style="max-height: 32em; height: 32em;">
          <div class="card-body">
            <div>
              <b-table
                sticky-header
                :items="lastTable"
                head-variant="dark"
              ></b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end last chart -->

    <!-- table Log Activity -->

    <div class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h5>Log Activity</h5>
            </div>
            <div class="table-responsive">
              <complete-table :loading="false" :data="tableLogActivity">
                <template #header>
                  <tr>
                    <th>Activity</th>
                    <th>User</th>
                    <th>Date</th>
                  </tr>
                </template>
                <template #defaultBody="{ item, i }">
                  <tr v-bind:key="i">
                    <td>{{ item.activitas }}</td>
                    <td>{{ item.user }}</td>
                    <td>{{ moment(item.tanggal).format("DD-MM-YYYY") }}</td>
                  </tr>
                </template>
              </complete-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end table Log Activity -->
    <!--end::Dashboard-->

    <v-dialog
      v-model="urgentDialog"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">Urgent</v-card-title>
        <v-card-text>
          <div class="card card-custom p-5 gutter-b">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Nickname</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Nickname"
                    v-model="filterReturnStatus.nickname"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Stasiun</label>

                  <b-form-select
                    v-model="filterReturnStatus.stasiun"
                    :options="formattedStationOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Title</label>
                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Title"
                    v-model="filterReturnStatus.title"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Scope</label>

                  <b-form-select
                    v-model="filterReturnStatus.scope"
                    :options="formattedScopeOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12"></div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text>
          <complete-table
            :loading="urgentLoading"
            :data="filteredDataUrgentList"
          >
            <template #header>
              <tr class="text-left">
                <th style="min-width: 150px" class="pl-7">
                  <span class="text-dark-75">Numbering</span>
                </th>
                <th style="min-width: 200px">Title</th>
                <th style="min-width: 100px">Status</th>
                <th style="min-width: 100px">Revision</th>
                <th style="min-width: 100px">Version</th>
                <th style="min-width: 200px">Date Receive</th>
                <th style="min-width: 100px">Delay</th>
                <th style="min-width: 150px">Outstanding</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.numbering }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.monitoring_asbuilt_status }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.revision_version }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.version }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{
                      moment(item.date_receive).isValid()
                        ? moment(item.date_receive).format("DD-MM-YYYY")
                        : ""
                    }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.delay }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.outstanding ? item.outstanding.name : null }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="urgentDialog = false">
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="delayDialog"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">Delay</v-card-title>
        <v-card-text>
          <div class="card card-custom p-5 gutter-b">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Nickname</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Nickname"
                    v-model="filterReturnStatus.nickname"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Stasiun</label>

                  <b-form-select
                    v-model="filterReturnStatus.stasiun"
                    :options="formattedStationOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Title</label>
                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Title"
                    v-model="filterReturnStatus.title"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Scope</label>

                  <b-form-select
                    v-model="filterReturnStatus.scope"
                    :options="formattedScopeOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12"></div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text>
          <complete-table :loading="delayLoading" :data="filteredDataDelayList">
            <template #header>
              <tr class="text-left">
                <th style="min-width: 150px" class="pl-7">
                  <span class="text-dark-75">Numbering</span>
                </th>
                <th style="min-width: 200px">Title</th>
                <th style="min-width: 100px">Status</th>
                <th style="min-width: 100px">Revision</th>
                <th style="min-width: 100px">Version</th>
                <th style="min-width: 200px">Date Receive</th>
                <th style="min-width: 100px">Delay</th>
                <th style="min-width: 150px">Outstanding</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.numbering }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.monitoring_asbuilt_status }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.revision_version }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.version }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{
                      moment(item.date_receive).isValid()
                        ? moment(item.date_receive).format("DD-MM-YYYY")
                        : ""
                    }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.delay }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.outstanding ? item.outstanding.name : null }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="delayDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="jumlahProyekDialog"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">Project Count</v-card-title>
        <v-card-text>
          <div class="card card-custom p-5 gutter-b">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Nickname</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Nickname"
                    v-model="filterJumlahProyek.nickname"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Project Owner</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Project Owner"
                    v-model="filterJumlahProyek.owner"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Project Leader</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Project Leader"
                    v-model="filterJumlahProyek.leader"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Contract Number</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Contract Number"
                    v-model="filterJumlahProyek.contract_no"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12"></div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text>
          <complete-table
            :loading="jumlahProyekLoading"
            :data="filteredDataJumlahProyek"
          >
            <template #header>
              <tr class="text-left">
                <th style="min-width: 100px" class="pl-7">
                  <span class="text-dark-75">Project Code</span>
                </th>
                <th style="min-width: 200px">Project Code</th>
                <th style="min-width: 100px">Nickname</th>
                <th style="min-width: 50px">Status</th>
                <th style="min-width: 200px">Owner</th>
                <th style="min-width: 100px">Contract Number</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.code }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.name }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.nickname }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.status }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.owner }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.nomor_contract }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="jumlahProyekDialog = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="designUpdatedDialog"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">Design Updated</v-card-title>

        <v-card-text>
          <div class="card card-custom p-5 gutter-b">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Nickname</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Nickname"
                    v-model="filterDesignUpdated.nickname"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Stasiun</label>

                  <b-form-select
                    v-model="filterDesignUpdated.stasiun"
                    :options="formattedStationOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Title</label>
                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Title"
                    v-model="filterDesignUpdated.title"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Scope</label>

                  <b-form-select
                    v-model="filterDesignUpdated.scope"
                    :options="formattedScopeOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12"></div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text>
          <complete-table
            :loading="designUpdatedLoading"
            :data="filteredDataDesignUpdated"
          >
            <template #header>
              <tr class="text-left">
                <th style="min-width: 50px" class="pl-7">
                  <span class="text-dark-75">No</span>
                </th>
                <th style="min-width: 100px">Numbering</th>
                <th style="min-width: 200px">Title</th>
                <th style="min-width: 100px">Planing Date</th>
                <th style="min-width: 100px">Return Item</th>
                <th style="min-width: 80px">Outstanding</th>
                <th style="min-width: 150px">Keterangan</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ i + 1 }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.numbering }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ moment(item.planning_date).format("DD-MM-YYYY") }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.return_item }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.outstanding_code }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.description }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="designUpdatedDialog = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dmpReleasedDialog"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">DMP Released</v-card-title>
        <v-card-text>
          <div class="card card-custom p-5 gutter-b">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Nickname</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Nickname"
                    v-model="filterDMPReleased.nickname"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Owner Proyek</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Owner Proyek"
                    v-model="filterDMPReleased.owner"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Revision Date</label>
                  <b-form-datepicker
                    id="input-large"
                    size="lg"
                    v-model="filterDMPReleased.revision_date"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="id"
                    placeholder="DD-MM-YYYY"
                    reset-button
                    reset-value=""
                  ></b-form-datepicker>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Contract Number</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Contract Number"
                    v-model="filterJumlahProyek.nomor_contract"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12"></div>

                <div class="col-md-6 py-2 col-sm-12 text-right">
                  <button
                    class="btn btn-primary btn-md"
                    @click="getDesigns(true)"
                  >
                    <i class="flaticon2-search-1"></i> Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text>
          <complete-table
            :loading="dmpReleasedLoading"
            :data="filteredDataDMPReleased"
          >
            <template #header>
              <tr class="text-left">
                <th style="min-width: 100px" class="pl-7">
                  <span class="text-dark-75">Project Code</span>
                </th>
                <th style="min-width: 100px">Nickname</th>
                <th style="min-width: 50px">Revision Date</th>
                <th style="min-width: 100px">Evp Engineering</th>
                <th style="min-width: 100px">Evp Project</th>
                <th style="min-width: 100px">Action</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.project_code }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.project ? item.project.nickname : "" }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ moment(item.revision_date).format("DD-MM-YYYY") }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.evp_name }}
                  </span>
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.vp_name }}
                  </span>
                </td>
                <td>
                  <b-button @click="downloadFile(item.file)" class="mr-3 mb-3">
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </b-button>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dmpReleasedDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="openItemMomDialog"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">Open Item MoM</v-card-title>
        <v-card-text>
          <div class="card card-custom p-5 gutter-b">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Nickname</label>
                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Nickname"
                    v-model="filterOpenItemMom.nickname"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Meeting Number</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Meeting Number"
                    v-model="filterOpenItemMom.nomor_rapat"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Meeting Date</label>
                  <b-form-datepicker
                    id="input-large"
                    size="lg"
                    v-model="filterOpenItemMom.tanggal_rapat"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="id"
                    placeholder="DD-MM-YYYY"
                    reset-button
                    reset-value=""
                  ></b-form-datepicker>
                </div>

                <div class="col-md-6 py-2 col-sm-12"></div>

                <div class="col-md-6 py-2 col-sm-12"></div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text>
          <complete-table
            :loading="openItemMomLoading"
            :data="filteredDataOpenItem"
          >
            <template #header>
              <tr class="text-left">
                <th style="min-width: 100px" class="pl-7">
                  <span class="text-dark-75">Point</span>
                </th>
                <th style="min-width: 100px">Uraian</th>
                <th style="min-width: 150px">Analisa Penyebab</th>
                <th style="min-width: 50px">Deadline</th>
                <th style="min-width: 100px">Status</th>
                <th style="min-width: 100px">PIC</th>
                <th style="min-width: 100px">Tindak Lanjut</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.point }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.description }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.cause }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.deadline }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.status }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.pic ? item.pic.name : "" }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.response }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="openItemMomDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="CRDialog"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">Change Management</v-card-title>
        <v-card-text>
          <div class="card card-custom p-5 gutter-b">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Nickname</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Nickname"
                    v-model="filterChangeManagement.nickname"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Type</label>

                  <b-form-select
                    v-model="filterChangeManagement.type"
                    :options="formattedChangeManagementType"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Title Change Management</label>
                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Title Change Management"
                    v-model="filterChangeManagement.title"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12"></div>

                <div class="col-md-6 py-2 col-sm-12"></div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text>
          <complete-table
            :loading="CRLoading"
            :data="filteredDataChangeManagement"
          >
            <template #header>
              <tr class="text-left">
                <th style="min-width: 150px" class="pl-7">
                  <span class="text-dark-75">Nomor Change Management</span>
                </th>
                <th style="min-width: 100px">Title</th>
                <th style="min-width: 250px">Proyek</th>
                <th style="min-width: 100px">Type</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.cr_number }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.project ? item.project.name : "" }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.type ? item.type.name : "" }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="CRDialog = false">
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="returnStatusDialog"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">Return Status</v-card-title>
        <v-card-text>
          <div class="card card-custom p-5 gutter-b">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Nickname</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Nickname"
                    v-model="filterReturnStatus.nickname"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Stasiun</label>

                  <b-form-select
                    v-model="filterReturnStatus.stasiun"
                    :options="formattedStationOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Title</label>
                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Title"
                    v-model="filterReturnStatus.title"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Scope</label>

                  <b-form-select
                    v-model="filterReturnStatus.scope"
                    :options="formattedScopeOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12"></div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text>
          <complete-table
            :loading="returnStatusLoading"
            :data="filteredDataReturnStatus"
          >
            <template #header>
              <tr class="text-left">
                <th style="min-width: 50px" class="pl-7">
                  <span class="text-dark-75">No</span>
                </th>
                <th style="min-width: 100px">Numbering</th>
                <th style="min-width: 100px">Title CR</th>
                <th style="min-width: 200px">Title</th>
                <th style="min-width: 100px">Planing Date</th>
                <th style="min-width: 100px">Return Item</th>
                <th style="min-width: 100px">Outstanding</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ i + 1 }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.project_code }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title_cr }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ moment(item.planning_date).format("DD-MM-YYYY") }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.return_item }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.outstanding }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="returnStatusDialog = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="planDateDialog"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">Plan Date</v-card-title>
        <v-card-text>
          <div class="card card-custom p-5 gutter-b">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Nickname</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Nickname"
                    v-model="filterPlanDate.nickname"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Stasiun</label>

                  <b-form-select
                    v-model="filterPlanDate.stasiun"
                    :options="formattedStationOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Title</label>
                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Title"
                    v-model="filterPlanDate.title"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Scope</label>

                  <b-form-select
                    v-model="filterPlanDate.scope"
                    :options="formattedScopeOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12"></div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text>
          <complete-table
            :loading="planDateLoading"
            :data="filteredDataPlanDate"
          >
            <template #header>
              <tr class="text-left">
                <th style="min-width: 50px" class="pl-7">
                  <span class="text-dark-75">No</span>
                </th>
                <th style="min-width: 100px">Numbering</th>
                <th style="min-width: 200px">Title</th>
                <th style="min-width: 100px">Planing Date</th>
                <th style="min-width: 100px">Return Item</th>
                <th style="min-width: 100px">Outstanding</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ i + 1 }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.numbering }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{
                      moment(item.planning_date).isValid()
                        ? moment(item.planning_date, "DD-MM-YYYYT").format()
                        : ""
                    }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.return_item }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.outstanding_code }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="planDateDialog = false">
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="uploadServerDialog"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">Upload Server</v-card-title>
        <v-card-text>
          <div class="card card-custom p-5 gutter-b">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Nickname</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Nickname"
                    v-model="filterUploadServer.nickname"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Stasiun</label>

                  <b-form-select
                    v-model="filterUploadServer.stasiun"
                    :options="formattedStationOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Title</label>
                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Title"
                    v-model="filterUploadServer.title"
                  ></b-form-input>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">Scope</label>

                  <b-form-select
                    v-model="filterUploadServer.scope"
                    :options="formattedScopeOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12"></div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text>
          <complete-table
            :loading="uploadServerLoading"
            :data="filteredDataUploadServer"
          >
            <template #header>
              <tr class="text-left">
                <th style="min-width: 50px" class="pl-7">
                  <span class="text-dark-75">No</span>
                </th>
                <th style="min-width: 100px">Numbering</th>
                <th style="min-width: 200px">Title</th>
                <th style="min-width: 100px">Planing Date</th>
                <th style="min-width: 80px">Status</th>
                <th style="min-width: 100px">Server</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ i + 1 }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.numbering }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ moment(item.planning_date).format("DD-MM-YYYY") }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.last_status ? item.last_status.name : "" }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.server }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="uploadServerDialog = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ChartBar from "@/view/content/widgets/dashboard/ChartBar.vue";
import ChartVertical from "@/view/content/widgets/dashboard/ChartBarVertical.vue";
import ChartLine from "@/view/content/widgets/dashboard/ChartLine.vue";
import {
  GET_PROGRESS_OVERVIEW_USER,
  GET_PROGRESS_OVERVIEW,
  GET_PROGRESS_CATEGORY,
  GET_PROGRESS_TYPE,
  GET_COUNTER_PROJECT,
  GET_COUNTER_DMP,
  GET_COUNTER_MOM,
  GET_COUNTER_PLAN,
  GET_COUNTER_CR,
  GET_DASHBOARD_TARGET_OVERVIEW,
  GET_DASHBOARD_TARGET_PLAN,
  GET_DASHBOARD_LOG_ACTIVITY,
  GET_DASHBOARD_DETAIL_TARGET_OVERVIEW,
  GET_DASHBOARD_URGENT,
  GET_COUNTER_UPLOAD_SERVER,
  GET_COUNTER_DESIGN_UPDATE,
} from "@/core/services/store/dashboard-main.module";
import { GET_PROJECT_ALL } from "@/core/services/store/project.module";
import { mapGetters } from "vuex";
import { arrayMoreThanOne } from "@/core/helper/array-validation.helper";
import { GET_PROJECT_DETAIL } from "@/core/services/store/project.module";
import CompleteTable from "@/view/content/widgets/advance-table/CompleteTable";
import ApiService from "../../core/services/api.service";
import { optionalData } from "../../core/helper/object-validation.helper";
import { GET_STATION } from "@/core/services/store/station.module";
import { GET_SCOPES } from "@/core/services/store/scope.module";
import { GET_USER_DETAIL_BY_ID } from "@/core/services/store/user.module";
import { showToast } from "../../core/helper/toast.helper";
import { GET_CHANGE_MANAGEMENT_TYPE } from "@/core/services/store/change-management-type.module";
import moment from "moment";

export default {
  name: "dashboard",
  data() {
    return {
      counterUrgent: 0,
      counterDelay: 0,
      uploadServerDialog: false,
      uploadServerLoading: true,
      uploadServerList: [],
      planDateDialog: false,
      planDateLoading: true,
      planDateList: [],
      CRDialog: false,
      CRLoading: true,
      CRList: [],
      openItemMomDialog: false,
      openItemMomLoading: true,
      openItemMomList: [],
      dmpReleasedDialog: false,
      dmpReleasedLoading: true,
      dmpReleasedList: [],
      jumlahProyekDialog: false,
      jumlahProyekLoading: true,
      jumlahProyekList: [],
      ProyekList: [],
      urgentDialog: false,
      urgentLoading: true,
      urgentList: [],
      delayDialog: false,
      delayLoading: true,
      delayList: [],
      designUpdatedDialog: false,
      designUpdatedLoading: true,
      designUpdatedList: [],
      counterReturnStatus: 0,
      returnStatusDialog: false,
      returnStatusLoading: true,
      returnStatusList: [],
      filter: {
        code_project: "",
      },

      filterJumlahProyek: {
        nickname: null,
        owner: null,
        leader: null,
        nomor_contract: null,
      },
      filterOpenItemMom: {
        nickname: null,
        nomor_rapat: null,
        tanggal_rapat: null,
      },
      filterDMPReleased: {
        nickname: null,
        owner: null,
        revision_date: null,
        nomor_contract: null,
      },
      filterReturnStatus: {
        nickname: null,
        stasiun: null,
        title: null,
        scope: null,
      },
      filterChangeManagement: {
        nickname: null,
        type: null,
        title: null,
      },
      filterDesignUpdated: {
        nickname: null,
        stasiun: null,
        title: null,
        scope: null,
      },
      filterPlanDate: {
        nickname: null,
        stasiun: null,
        title: null,
        scope: null,
      },
      filterUploadServer: {
        nickname: null,
        stasiun: null,
        title: null,
        scope: null,
      },
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      statusOptions: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
      ],
      tableOverview: [
        {
          scope_code: "",
          scope: "",
          no_target: "",
          remain: "",
          next_mo: "",
          delay: "",
          urgent: "",
        },
      ],
      tableUrgent: [
        {
          scope_code: "",
          scope: "",
          urgent: "",
        },
      ],
      tableLogActivity: [
        {
          activitas: "",
          user: "",
          tanggal: "",
        },
      ],
      chartPlanDate: {
        category: [],
        series: [],
      },
      chartProgressType: {
        category: [],
        series: [],
      },
      chartProgressCategory: {
        category: [],
        series: [],
      },
      chartProgressOverview: {
        category: [],
        series: [],
      },
      chartProgressOverviewUser: {
        title: "",
        category: [],
        series: [],
      },
      lastTable: [],
      columns: [
        {
          label: "No",
          field: "numRow",
        },
        {
          label: "Numbering",
          field: "numbering",
        },
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Planning Date",
          field: "planning_date",
        },
      ],
    };
  },

  components: {
    ChartBar,
    ChartVertical,
    ChartLine,
    CompleteTable,
  },

  async created() {
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    await this.$store.dispatch(GET_COUNTER_PROJECT);
    await this.$store.dispatch(GET_COUNTER_DMP);
    await this.$store.dispatch(GET_COUNTER_MOM);
    await this.$store.dispatch(GET_COUNTER_CR);
    await this.$store.dispatch(GET_COUNTER_PLAN);
    await this.$store.dispatch(GET_COUNTER_DESIGN_UPDATE);
    await this.$store.dispatch(GET_COUNTER_UPLOAD_SERVER);
    await this.$store.dispatch(GET_PROJECT_DETAIL, "design");
    await this.$store.dispatch(GET_USER_DETAIL_BY_ID, this.currentUser.data.id);
    await this.$store.dispatch(GET_PROJECT_ALL);
  },

  mounted() {
    this.getCounterUrgent();
    this.getCounterDelay();

    this.getCounterReturnStatus();
    this.getStations();
    this.getScopes();
    this.getChangeManagementTypes();
    const jobdeskArray = ["koor","director","evp","vp","manager","ppc", "design", "interface","drafter","planner","enginer","admin"];

    ApiService.setHeader();
    ApiService.query("counter/project/list",{
      params: {
        jobdesks: jobdeskArray
      }
    }).then((response) => {
      this.ProyekList = response.data.data;
      this.jumlahProyekLoading = false;
    });
  },

  computed: {
    ...mapGetters([
      "currentProjectDetails",
      "currentCounterProject",
      "currentCounterDMP",
      "currentCounterMOM",
      "currentCounterCR",
      "currentCounterPlan",
      "currentCounterDesignUpdated",
      "currentTargetOverview",
      "currentTargetPlan",
      "currentProgressType",
      "currentProgressCategory",
      "currentProgressOverview",
      "currentProgressOverviewUser",
      "currentDashboardLogActivity",
      "currentDetailTargetOverview",
      "currentUserDetail",
      "currentDashboardUrgent",
      "currentCounterUploadServer",
      "currentUserPersonal",
      "currentUser",
      "currentStations",
      "currentScopes",
      "currentChangeManagementType",
      "currentProjectAll",
    ]),

    formattedChangeManagementType() {
      let types = [];

      if (arrayMoreThanOne(this.currentChangeManagementType)) {
        types = this.currentChangeManagementType.map((type) => {
          return {
            value: type.code,
            text: type.name,
          };
        });
      }

      return [{ value: null, text: "Pilih Type" }, ...types];
    },

    filteredDataJumlahProyek() {
      const nickname = this.filterJumlahProyek.nickname;
      const owner = this.filterJumlahProyek.owner;
      const leader = this.filterJumlahProyek.leader;
      const noContract = this.filterJumlahProyek.contract_no;
      return this.jumlahProyekList?.filter(
        (proyek) =>
          (nickname
            ? proyek.nickname?.toUpperCase()?.includes(nickname?.toUpperCase())
            : true) &&
          (owner
            ? proyek.owner?.toUpperCase()?.includes(owner?.toUpperCase())
            : true) &&
          (leader
            ? proyek.leader?.toUpperCase()?.includes(leader?.toUpperCase())
            : true) &&
          (noContract
            ? proyek.contract_no
                ?.toUpperCase()
                ?.includes(noContract?.toUpperCase())
            : true)
      );
    },

    filteredDataDMPReleased() {
      const nickname = this.filterDMPReleased.nickname;
      const owner = this.filterDMPReleased.owner;
      const revisionDate = this.filterDMPReleased.revision_date;
      const noContract = this.filterDMPReleased.contract_no;

      return this.dmpReleasedList?.filter(
        (proyek) =>
          (nickname
            ? proyek.project?.nickname
                ?.toUpperCase()
                ?.includes(nickname?.toUpperCase())
            : true) &&
          (owner
            ? proyek.project?.owner
                ?.toUpperCase()
                ?.includes(owner?.toUpperCase())
            : true) &&
          (revisionDate
            ? proyek.revision_date
                ?.toUpperCase()
                ?.includes(revisionDate?.toUpperCase())
            : true) &&
          (noContract
            ? proyek.project?.contract_no
                ?.toUpperCase()
                ?.includes(noContract?.toUpperCase())
            : true)
      );
    },

    filteredDataOpenItem() {
      const nickname = this.filterOpenItemMom.nickname;
      const nomorRapat = this.filterOpenItemMom.nomor_rapat;
      const tanggalRapat = this.filterOpenItemMom.tanggal_rapat;

      return this.openItemMomList?.filter(
        (proyek) =>
          (nickname
            ? proyek.project?.nickname
                ?.toUpperCase()
                ?.includes(nickname?.toUpperCase())
            : true) &&
          (nomorRapat
            ? proyek.meeting?.code
                ?.toUpperCase()
                ?.includes(nomorRapat?.toUpperCase())
            : true) &&
          (tanggalRapat
            ? proyek.meeting?.date
                ?.toUpperCase()
                ?.includes(tanggalRapat?.toUpperCase())
            : true)
      );
    },

    filteredDataReturnStatus() {
      const nickname = this.filterReturnStatus.nickname;
      const stasiun = this.filterReturnStatus.stasiun;
      const title = this.filterReturnStatus.title;
      const scope = this.filterReturnStatus.scope;

      return this.returnStatusList?.filter(
        (proyek) =>
          (nickname
            ? proyek.project?.nickname
                ?.toUpperCase()
                ?.includes(nickname?.toUpperCase())
            : true) &&
          (stasiun
            ? proyek.station?.code
                ?.toUpperCase()
                ?.includes(stasiun?.toUpperCase())
            : true) &&
          (title
            ? proyek.title?.toUpperCase()?.includes(title?.toUpperCase())
            : true) &&
          (scope
            ? proyek.scope?.code?.toUpperCase()?.includes(scope?.toUpperCase())
            : true)
      );
    },

    filteredDataUrgentList() {
      const nickname = this.filterReturnStatus.nickname;
      const stasiun = this.filterReturnStatus.stasiun;
      const title = this.filterReturnStatus.title;
      const scope = this.filterReturnStatus.scope;

      return this.urgentList?.filter(
        (proyek) =>
          (nickname
            ? proyek.project?.nickname
                ?.toUpperCase()
                ?.includes(nickname?.toUpperCase())
            : true) &&
          (stasiun
            ? proyek.station?.code
                ?.toUpperCase()
                ?.includes(stasiun?.toUpperCase())
            : true) &&
          (title
            ? proyek.title?.toUpperCase()?.includes(title?.toUpperCase())
            : true) &&
          (scope
            ? proyek.scope?.code?.toUpperCase()?.includes(scope?.toUpperCase())
            : true)
      );
    },

    filteredDataDelayList() {
      const nickname = this.filterReturnStatus.nickname;
      const stasiun = this.filterReturnStatus.stasiun;
      const title = this.filterReturnStatus.title;
      const scope = this.filterReturnStatus.scope;

      return this.delayList?.filter(
        (proyek) =>
          (nickname
            ? proyek.project?.nickname
                ?.toUpperCase()
                ?.includes(nickname?.toUpperCase())
            : true) &&
          (stasiun
            ? proyek.station?.code
                ?.toUpperCase()
                ?.includes(stasiun?.toUpperCase())
            : true) &&
          (title
            ? proyek.title?.toUpperCase()?.includes(title?.toUpperCase())
            : true) &&
          (scope
            ? proyek.scope?.code?.toUpperCase()?.includes(scope?.toUpperCase())
            : true)
      );
    },

    filteredDataChangeManagement() {
      const nickname = this.filterChangeManagement.nickname;
      const type = this.filterChangeManagement.type;
      const title = this.filterChangeManagement.title;

      return this.CRList?.filter(
        (proyek) =>
          (nickname
            ? proyek.project?.nickname
                ?.toUpperCase()
                ?.includes(nickname?.toUpperCase())
            : true) &&
          (title
            ? proyek.title?.toUpperCase()?.includes(title?.toUpperCase())
            : true) &&
          (type
            ? proyek.type?.code?.toUpperCase()?.includes(type?.toUpperCase())
            : true)
      );
    },

    filteredDataDesignUpdated() {
      const nickname = this.filterDesignUpdated.nickname;
      const stasiun = this.filterDesignUpdated.stasiun;
      const title = this.filterDesignUpdated.title;
      const scope = this.filterDesignUpdated.scope;

      return this.designUpdatedList?.filter(
        (proyek) =>
          (nickname
            ? proyek.project?.nickname
                ?.toUpperCase()
                ?.includes(nickname?.toUpperCase())
            : true) &&
          (title
            ? proyek.title?.toUpperCase()?.includes(title?.toUpperCase())
            : true) &&
          (scope
            ? proyek.scope?.code?.toUpperCase()?.includes(scope?.toUpperCase())
            : true) &&
          (stasiun
            ? proyek.station?.code
                ?.toUpperCase()
                ?.includes(stasiun?.toUpperCase())
            : true)
      );
    },

    filteredDataPlanDate() {
      const nickname = this.filterPlanDate.nickname;
      const stasiun = this.filterPlanDate.stasiun;
      const title = this.filterPlanDate.title;
      const scope = this.filterPlanDate.scope;

      return this.planDateList?.filter(
        (proyek) =>
          (nickname
            ? proyek.project?.nickname
                ?.toUpperCase()
                ?.includes(nickname?.toUpperCase())
            : true) &&
          (title
            ? proyek.title?.toUpperCase()?.includes(title?.toUpperCase())
            : true) &&
          (scope
            ? proyek.scope?.code?.toUpperCase()?.includes(scope?.toUpperCase())
            : true) &&
          (stasiun
            ? proyek.station?.code
                ?.toUpperCase()
                ?.includes(stasiun?.toUpperCase())
            : true)
      );
    },

    filteredDataUploadServer() {
      const nickname = this.filterUploadServer.nickname;
      const stasiun = this.filterUploadServer.stasiun;
      const title = this.filterUploadServer.title;
      const scope = this.filterUploadServer.scope;

      return this.uploadServerList?.filter(
        (proyek) =>
          (nickname
            ? proyek.project?.nickname
                ?.toUpperCase()
                ?.includes(nickname?.toUpperCase())
            : true) &&
          (title
            ? proyek.title?.toUpperCase()?.includes(title?.toUpperCase())
            : true) &&
          (scope
            ? proyek.scope?.code?.toUpperCase()?.includes(scope?.toUpperCase())
            : true) &&
          (stasiun
            ? proyek.station?.code
                ?.toUpperCase()
                ?.includes(stasiun?.toUpperCase())
            : true)
      );
    },

    formattedScopeOptions() {
      let scopes = [];

      if (arrayMoreThanOne(this.currentScopes)) {
        scopes = this.currentScopes.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Pilih Scope" }, ...scopes];
    },

    formattedStationOptions() {
      let stations = [];
      if (arrayMoreThanOne(this.currentStations.data)) {
        stations = this.currentStations.data?.map((stasiun) => {
          return {
            value: stasiun.code,
            text: stasiun.name,
          };
        });
      }

      return [{ value: null, text: "Pilih Stasiun" }, ...stations];
    },

    currentLogin() {
      return this.currentUserDetail;
    },

    currentPersonal() {
      return this.currentUserPersonal;
    },

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.ProyekList)) {
        // console.log("ZXC", this.jumlahProyekList);
        projects = this.ProyekList.map((project) => {
          return {
            value: project.code,
            text: project.code + "-" + project.nickname,
          };
        });
      }

      return [{ value: null, text: "Choose Project" }, ...projects];
    },

    tableDetailOverview() {
      let dataDetail = [];
      if (arrayMoreThanOne(this.currentDetailTargetOverview)) {
        // console.log("ini", this.currentDetailTargetOverview);
        dataDetail = this.currentDetailTargetOverview.map((project, index) => {
          return {
            ...project,
            numRow: index + 1,
          };
        });
      }
      return [...dataDetail];
    },

    counterUploadServer() {
      var result = this.currentCounterUploadServer;

      if (result != null) {
        return `${result.design_uploaded}/${result.design_server}`;
      }

      return "0/0";
    },
  },

  methods: {
    getChangeManagementTypes() {
      if (!arrayMoreThanOne(this.changeManagementTypes)) {
        this.$store.dispatch(GET_CHANGE_MANAGEMENT_TYPE, {
          params: this.filter,
        });
      }
    },
    getScopes() {
      if (!arrayMoreThanOne(this.currentScopes)) {
        this.$store.dispatch(GET_SCOPES, {
          params: {},
        });
      }
    },
    getStations() {
      if (!arrayMoreThanOne(this.currentStations.data)) {
        this.$store.dispatch(GET_STATION, {});
      }
    },

    downloadFile(url) {
      var urlArray = url.split("/");
      var filename = urlArray[urlArray.length - 1];
      ApiService.setHeader();

      ApiService.postDownload("ftp/download", {
        file_url: url,
      })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(
            new Blob([response?.data])
          );

          const fileLink = document.createElement("a");

          fileLink.href = fileURL;

          fileLink.setAttribute("download", filename);

          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          showToast("Error", "Failed download", "danger");
        });
    },

    showPlanDateList() {
      this.planDateDialog = true;
      this.planDateLoading = true;
      ApiService.setHeader();
      ApiService.query("counter/plan/list").then((response) => {
        this.planDateList = response.data.data;
        this.planDateLoading = false;
      });
    },

    showUploadServerList() {
      this.uploadServerDialog = true;
      this.uploadServerLoading = true;
      ApiService.setHeader();
      ApiService.query("counter/server/uploaded/list").then((response) => {
        this.uploadServerList = response.data.data;
        this.uploadServerLoading = false;
      });
    },

    showCRList() {
      this.CRDialog = true;
      this.CRLoading = true;
      ApiService.setHeader();
      ApiService.query("counter/cr/list").then((response) => {
        this.CRList = response.data.data;
        this.CRLoading = false;
      });
    },

    showReturnStatusList() {
      this.returnStatusDialog = true;
      this.returnStatusLoading = true;
      ApiService.setHeader();
      ApiService.query("counter/status/return/list").then((response) => {
        this.returnStatusList = response.data.data;
        this.returnStatusLoading = false;
      });
    },

    showOpenItemMomList() {
      this.openItemMomDialog = true;
      this.openItemMomLoading = true;
      ApiService.setHeader();
      ApiService.query("counter/mom/list").then((response) => {
        this.openItemMomList = response.data.data;
        this.openItemMomLoading = false;
      });
    },

    showDMPReleasedList() {
      this.dmpReleasedDialog = true;
      this.dmpReleasedLoading = true;
      ApiService.setHeader();
      ApiService.query("counter/dmp/list").then((response) => {
        this.dmpReleasedList = response.data.data;
        this.dmpReleasedLoading = false;
      });
    },

    showJumlahProyekList() {
      this.jumlahProyekDialog = true;
      this.jumlahProyekLoading = true;
      ApiService.setHeader();
      ApiService.query("counter/project/list").then((response) => {
        this.jumlahProyekList = response.data.data;
        this.jumlahProyekLoading = false;
      });
    },

    showUrgentList() {
      this.urgentDialog = true;
      this.urgentLoading = true;
      ApiService.setHeader();
      ApiService.query("counter/urgent/list").then((response) => {
        this.urgentList = response.data.data;
        this.urgentLoading = false;
      });
    },

    showDelayList() {
      this.delayDialog = true;
      this.delayLoading = true;
      ApiService.setHeader();
      ApiService.query("counter/delay/list").then((response) => {
        this.delayList = response.data.data;
        this.delayLoading = false;
      });
    },

    showDesignUpdatedList() {
      this.designUpdatedDialog = true;
      this.designUpdatedLoading = true;
      ApiService.setHeader();
      ApiService.query("counter/design/updated/list").then((response) => {
        this.designUpdatedList = response.data.data;
        this.designUpdatedLoading = false;
      });
    },

    optionalObject(object, key) {
      return optionalData(object, key);
    },

    getCounterUrgent() {
      ApiService.setHeader();

      ApiService.query("counter/urgent").then((response) => {
        this.counterUrgent = response.data.data;
      });
    },

    getCounterDelay() {
      ApiService.setHeader();

      ApiService.query("counter/delay").then((response) => {
        this.counterDelay = response.data.data;
      });
    },

    getCounterReturnStatus() {
      ApiService.setHeader();

      ApiService.query("counter/status/return").then((response) => {
        this.counterReturnStatus = response.data.data;
      });
    },

    toPlanDatePage(name) {
      this.$router.push({
        name: name,
      });
    },

    onViewDetail(data, target) {
      const dataParams = {
        params: {
          project_code:
            this.filter.code_project === null ? "" : this.filter.code_project,
          scope_code: data.scope_code,
          target: target,
        },
      };
      this.$store.dispatch(GET_DASHBOARD_DETAIL_TARGET_OVERVIEW, dataParams);
      this.$refs["view-detail-target"].show();
    },

    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, "design");
    },
    onTampilkanData() {
      this.getTargetOverview();
      this.getTargetPlan();
      this.getProgressType();
      this.getProgressCategory();
      this.getProgressOverview();
      this.getProgressOverviewUser();
      this.getLogActivity();
      this.getUrgent();
    },

    getLogActivity() {
      this.$store
        .dispatch(GET_DASHBOARD_LOG_ACTIVITY, this.filter.code_project)
        .then(() => this.setLogActivity(this.currentDashboardLogActivity));
    },

    getTargetOverview() {
      this.$store
        .dispatch(GET_DASHBOARD_TARGET_OVERVIEW, this.filter.code_project)
        .then(() => this.setTargetOverview(this.currentTargetOverview));
    },

    getUrgent() {
      const dataParams = {
        code_project:
          this.filter.code_project === null ? "" : this.filter.code_project,
        pic_code: this.currentLogin.pic.code,
      };
      this.$store
        .dispatch(GET_DASHBOARD_URGENT, dataParams)
        .then(() => this.setUrgent(this.currentDashboardUrgent));
    },

    getTargetPlan() {
      this.$store
        .dispatch(GET_DASHBOARD_TARGET_PLAN, this.filter.code_project)
        .then(() => this.setTargetPlan(this.currentTargetPlan));
    },

    getProgressType() {
      this.$store
        .dispatch(GET_PROGRESS_TYPE, this.filter.code_project)
        .then(() => this.setProgressType(this.currentProgressType));
    },

    getProgressCategory() {
      this.$store
        .dispatch(GET_PROGRESS_CATEGORY, this.filter.code_project)
        .then(() => this.setProgressCategory(this.currentProgressCategory));
    },

    getProgressOverview() {
      this.$store
        .dispatch(GET_PROGRESS_OVERVIEW, this.filter.code_project)
        .then(() => this.setProgressOverview(this.currentProgressOverview));
    },

    getProgressOverviewUser() {
      this.$store
        .dispatch(GET_PROGRESS_OVERVIEW_USER, this.filter.code_project)
        .then(() =>
          this.setProgressOverviewUser(this.currentProgressOverviewUser)
        );
    },

    setLogActivity(listActivity) {
      this.tableLogActivity = [];
      listActivity.map((target) =>
        this.tableLogActivity.push({
          activitas: target.description,
          user: target.user?.name,
          tanggal: target.date_time,
        })
      );
    },

    setProgressOverview(listTarget) {
      this.chartProgressOverview.category = [];
      this.chartProgressOverview.series = [];
      let listProgress = [];

      listTarget.map((data) => {
        this.chartProgressOverview.category.push(data.scope.name);
        data.progress.map((prog) => {
          let filter = listProgress.filter(
            (status) => status === prog.status.name
          );
          if (filter.length === 0) {
            listProgress.push(prog.status.name);
          }
        });
      });

      for (let i = 0; i < listProgress.length; i++) {
        this.chartProgressOverview.series.push({
          name: listProgress[i],
          data: this.setDataProgressOverview(listTarget, listProgress[i]),
        });
      }
    },

    setProgressOverviewUser(listTarget) {
      this.chartProgressOverviewUser.title = "";
      this.chartProgressOverviewUser.category = [];
      this.chartProgressOverviewUser.series = [];
      let listProgress = [];

      this.chartProgressOverviewUser.title =
        "Progress Overview(" + this.currentLogin.pic.code + ")";

      // console.log("title", this.chartProgressOverviewUser.title);

      listTarget.map((data) => {
        this.chartProgressOverviewUser.category.push(data.scope.name);
        data.progress.map((prog) => {
          let filter = listProgress.filter(
            (status) => status === prog.status.name
          );
          if (filter.length === 0) {
            listProgress.push(prog.status.name);
          }
        });
      });

      for (let i = 0; i < listProgress.length; i++) {
        this.chartProgressOverviewUser.series.push({
          name: listProgress[i],
          data: this.setDataProgressOverview(listTarget, listProgress[i]),
        });
      }
    },

    setDataProgressOverview(listTarget, progStatus) {
      let result = [];
      listTarget.map((data) => {
        let filter = data.progress.filter(
          (prog) => prog.status.name === progStatus
        );
        if (filter.length > 0) {
          result.push(filter[0].progress);
        } else {
          result.push(0);
        }
      });

      return result;
    },

    setTargetOverview(listTarget) {
      this.tableOverview = [];
      listTarget.map((target) =>
        this.tableOverview.push({
          scope_code: target.scope?.code,
          scope: target.scope?.name,
          no_target: target.no_target,
          remain: target.remain,
          next_mo: target.next_month,
          delay: target.delay,
          urgent: target.urgent,
        })
      );
    },

    setUrgent(listUrgent) {
      this.tableUrgent = [];
      listUrgent.map((data) =>
        this.tableUrgent.push({
          scope_code: data.scope?.code,
          scope: data.scope?.name,
          urgent: data.urgent,
        })
      );
    },

    setProgressType(listTarget) {
      this.chartProgressType.category = [];
      this.chartProgressType.series = [];

      listTarget.map((data) => {
        data.progress.map((prog) => {
          let filter = this.chartProgressType.category.filter(
            (type) => type === prog.type.name
          );

          if (filter.length === 0) {
            this.chartProgressType.category.push(prog.type.name);
          }
        });
      });

      listTarget.map((data) => {
        this.chartProgressType.series.push({
          name: data.scope.name,
          data: this.setDataProgressType(data.progress),
        });
      });

      // console.log("TypeProgress", this.chartProgressType);
    },

    setProgressCategory(listTarget) {
      this.chartProgressCategory.category = [];
      this.chartProgressCategory.series = [];

      listTarget.map((data) => {
        data.progress.map((prog) => {
          let filter = this.chartProgressCategory.category.filter(
            (type) => type === prog.category.name
          );

          if (filter.length === 0) {
            this.chartProgressCategory.category.push(prog.category.name);
          }
        });
      });

      listTarget.map((data) => {
        this.chartProgressCategory.series.push({
          name: data.scope.name,
          data: this.setDataProgressCategory(data.progress),
        });
      });

      // console.log("letChart0", this.chartProgressCategory);
    },

    setDataProgressType(listProgress) {
      let result = [];
      for (let i = 0; i < this.chartProgressType.category.length; i++) {
        let filter = listProgress.filter(
          (c) => c.type.name === this.chartProgressType.category[i]
        );
        if (filter.length > 0) {
          result.push(filter[0].progress);
        } else {
          result.push(0);
        }
      }
      return result;
    },

    setDataProgressCategory(listProgress) {
      let result = [];
      for (let i = 0; i < this.chartProgressCategory.category.length; i++) {
        let filter = listProgress.filter(
          (c) => c.category.name === this.chartProgressCategory.category[i]
        );
        if (filter.length > 0) {
          result.push(filter[0].progress);
        } else {
          result.push(0);
        }
      }
      return result;
    },
    setTargetPlan(listTarget) {
      let rawTable = [];
      this.lastTable = [];
      rawTable = listTarget.filter((c) => c.plan_date !== null);

      rawTable.map((data) =>
        this.lastTable.push({
          plan_date: moment(data.plan_date).format("DD-MM-YYYY"),
          closed: data.closed,
          target: data.target,
        })
      );

      let target = [];
      let closed = [];
      rawTable.map((data) => target.push(data.target_persent));
      rawTable.map((data) => closed.push(data.closed_persent));

      this.chartPlanDate.category = [];
      this.chartPlanDate.series = [];
      this.lastTable.map((data) =>
        this.chartPlanDate.category.push(
          data.plan_date === null ? "" : data.plan_date
        )
      );
      this.chartPlanDate.series.push({
        name: "Target",
        data: target,
      });

      this.chartPlanDate.series.push({
        name: "Closed",
        data: closed,
      });
    },

    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
