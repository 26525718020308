<template>
  <div id="chart">
    <apexchart
      type="area"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "ChartLine",
  props: {
    series: {
      default: () => [],
      type: Array,
    },
    category: {
      default: () => [],
      type: Array,
    },
    title: {
      default: "",
      type: String,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: this.title,
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.category,
        },
        yaxis: {
          title: {
            text: "Percentage",
          },
          tickAmount: 2,
          min: 0,
          max: 100,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  },
  methods: {},
  mounted() {
    // console.log(this.category);
  },
  computed: {
    currentCategory() {
      return this.category;
    },
  },

  watch: {
    currentCategory(value) {
      if (!value) {
        return;
      }

      this.chartOptions = {
        xaxis: {
          categories: this.category,
        },
      };
    },
  },
};
</script>
